import { Box, Button, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form'

import { Modal } from '../../../../components'

interface AlertSessionMediaChangModalProps {
  showSessionMediaAlertModal: boolean
  setShowSessionMediaAlertModal: Dispatch<SetStateAction<boolean>>
  handleSubmit: UseFormHandleSubmit<FieldValues>
  onSubmit: (values: FieldValues) => Promise<void>
}

export const AlertSessionMediaChangModal = ({
  showSessionMediaAlertModal,
  setShowSessionMediaAlertModal,
  handleSubmit,
  onSubmit,
}: AlertSessionMediaChangModalProps) => {
  return (
    <Modal
      open={showSessionMediaAlertModal}
      setOpen={setShowSessionMediaAlertModal}
      title={'Alert'}
      ModalContent={
        <Box sx={{ margin: '35px', height: '100%' }}>
          <Box sx={{ marginBottom: '25px' }}>
            <Typography sx={{ fontSize: '16px', lineHeight: '19px' }}>
              Looks like you want to change peer group start date / end date /
              frequency, doing so will remove media from all peer group
              sessions, you will need to add media to all sessions again.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'stretch',
              alignContent: 'stretch',
            }}
          >
            <Box
              sx={{
                minHeight: '100%',
                width: '45%',
                padding: '5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            ></Box>
            <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='error'
                sx={{ marginRight: '10px' }}
                onClick={handleSubmit((vals) => {
                  setShowSessionMediaAlertModal(false)
                  return onSubmit(vals)
                })}
              >
                Save Changes
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                sx={{ marginRight: '10px' }}
                onClick={() => {
                  setShowSessionMediaAlertModal(false)
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      }
    />
  )
}
