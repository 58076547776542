import { Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormHandleSubmit, UseFormReset } from 'react-hook-form'

import { FormInstructions, NavigationLink } from '../../../../components'
import {
  LinearProgressWithLabel,
  Phase,
} from '../../../../components/Progress/ProgressWithLabel'
import { PeerGroupDetails } from '../../../../providers/PeerGroups/types'
import { useProviderDetails } from '../../../../providers/ProviderDetails/ProviderDetails.provider'
import {
  FieldObjectEditable,
  FieldObjectNonEditable,
} from '../../../../utilities/Forms/FieldObject.partial'
import { Field, SectionFields } from '../../../../utilities/Forms/types'
import { MonthlyOccurrenceField } from '../MonthlyOccurrenceField'
import { ViewType } from '../types'
import { AlertSessionMediaChangModal } from './AlertSessionMediaChangModal'

interface AddUpdateViewProps {
  peerGroupId: number | null
  peerGroupDetails: PeerGroupDetails | null
  peerGroupDetailsEditMode: boolean
  peerGroupDetailFields: SectionFields
  viewMode: number
  setViewMode: React.Dispatch<React.SetStateAction<ViewType>>
  setPeerGroupId: React.Dispatch<React.SetStateAction<number | null>>
  watchedFields: { name: string; value: any }[] | null
  submitting: boolean
  activePhase: Phase
  reset: UseFormReset<FieldValues>
  setShowCancelModalMode: React.Dispatch<React.SetStateAction<boolean>>
  showAddSessionMediaModal?: boolean
  setShowAddSessionMediaModal?: React.Dispatch<React.SetStateAction<boolean>>
  showSessionMediaAlertModal: boolean
  setShowSessionMediaAlertModal: Dispatch<SetStateAction<boolean>>
  setSubmitting: Dispatch<SetStateAction<boolean>>
  handleSubmit: UseFormHandleSubmit<FieldValues>
  onSubmit: (values: FieldValues) => Promise<void>
}

export const AddUpdateView = ({
  peerGroupDetails,
  peerGroupDetailsEditMode,
  peerGroupDetailFields,
  setViewMode,
  setPeerGroupId,
  watchedFields,
  submitting,
  activePhase,
  reset,
  setShowCancelModalMode,
  setShowAddSessionMediaModal,
  showSessionMediaAlertModal,
  setShowSessionMediaAlertModal,
  setSubmitting,
  handleSubmit,
  onSubmit,
}: AddUpdateViewProps) => {
  const { myProviderDetails } = useProviderDetails()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          paddingLeft: 7,
          paddingRight: 2,
          display: 'flex',
          justifyContent: 'space-around',
          height: '100%',
        }}
      >
        {showSessionMediaAlertModal ? (
          <AlertSessionMediaChangModal
            showSessionMediaAlertModal={showSessionMediaAlertModal}
            setShowSessionMediaAlertModal={setShowSessionMediaAlertModal}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        ) : null}
        <Box
          id='column-left'
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          {peerGroupDetailFields.map((section) => {
            const frequencyFieldExists = section.fields.some(
              (field) => field.label === 'Frequency'
            )
            return (
              (section?.dependentOn?.key
                ? watchedFields?.find(
                    (x) => x.name === section?.dependentOn?.key
                  )?.value === section?.dependentOn?.being
                : true) && (
                <Box
                  key={section.id || section.sectionLabel}
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: 0.5,
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: 18,
                          marginBottom: 1,
                        }}
                      >
                        {section.sectionLabel}
                      </Typography>
                      {!!section.hasInstructions &&
                        peerGroupDetailsEditMode && <FormInstructions />}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        height: 'min-content',
                      }}
                    >
                      {section.fields.map((field: Field) => {
                        if (!peerGroupDetailsEditMode) {
                          if (field.label === 'Media') {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                }}
                                key={field.label}
                              >
                                <FieldObjectNonEditable
                                  field={field}
                                  key={field.label}
                                />
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    if (setShowAddSessionMediaModal) {
                                      setShowAddSessionMediaModal(true)
                                    }
                                  }}
                                >
                                  Select Media for Peer Group Sessions
                                </Button>
                              </div>
                            )
                          } else {
                            return (
                              <FieldObjectNonEditable
                                field={field}
                                key={field.label}
                              />
                            )
                          }
                        }
                        if (peerGroupDetailsEditMode) {
                          return (
                            <FieldObjectEditable
                              field={field}
                              key={field.label}
                            />
                          )
                        }
                      })}
                      {frequencyFieldExists && (
                        <MonthlyOccurrenceField watchedFields={watchedFields} />
                      )}
                    </Box>
                  </Box>
                </Box>
              )
            )
          })}
          {!peerGroupDetailsEditMode ? (
            <Box sx={{ display: 'flex', margin: '10px' }}>
              <Button
                variant='contained'
                onClick={() => setViewMode(ViewType.Edit)}
                sx={{ marginRight: '10px' }}
              >
                Edit Peer Group
              </Button>
              {myProviderDetails?.accessRoles &&
                (myProviderDetails?.accessRoles[0].description === 'Admin' ||
                  myProviderDetails?.accessRoles[0].description ===
                    'SuperAdmin') && (
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => setShowCancelModalMode(true)}
                  >
                    Delete Peer Group
                  </Button>
                )}
            </Box>
          ) : (
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', margin: '10px', width: '100%' }}>
                <NavigationLink
                  label='Cancel'
                  direction='backward'
                  handleClick={() => {
                    setPeerGroupId(null)
                    setViewMode(ViewType.List)
                    reset()
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    margin: '10px',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant='contained'
                    type='button'
                    sx={{ mr: '10px' }}
                    onClick={handleSubmit((vals) => {
                      if (
                        peerGroupDetails &&
                        (!dayjs(vals.startDate).isSame(
                          dayjs(peerGroupDetails.startDate),
                          'day'
                        ) ||
                          !dayjs(vals.endDate).isSame(
                            dayjs(peerGroupDetails.endDate),
                            'day'
                          ) ||
                          vals.frequency !== peerGroupDetails.frequency)
                      ) {
                        setShowSessionMediaAlertModal(true)
                      } else {
                        return onSubmit(vals)
                      }
                    })}
                  >
                    Save
                  </Button>
                  {submitting && (
                    <LinearProgressWithLabel progress={activePhase} />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
